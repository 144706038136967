import React, {useEffect, useState} from 'react';
import { styled } from '@abyss/web/tools/styled';
import { Link } from '@abyss/web/ui/Link';
import { Layout } from '@abyss/web/ui/Layout';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { NavMenuPrimitives } from '@abyss/web/ui/NavMenu';
import { AccountSection } from "@src/app/AccountSection";
import { Flex } from "@abyss/web/ui/Flex";
import { PageHeaderPrimitives } from '@abyss/web/ui/PageHeader';
import { StateRouter } from '@abyss/web/ui/StateRouter';
import { config } from "@abyss/web/tools/config";
import { useRouter } from '@abyss/web/hooks/useRouter';
import { useCountdown} from 'usehooks-ts'
import hotkeys from 'hotkeys-js';
import { useToast } from '@abyss/web/hooks/useToast';
import { useDebounceCallback } from 'usehooks-ts'
import { dayjs } from '@abyss/web/tools/dayjs';
import { useLogout } from "@src/hooks/user/useLogout";
import { useHasRole } from "@src/hooks/useHasRole";

const IS_LOCAL = config('APP_ENV') === 'local';

const { toast } = useToast();
const SIDEBAR_WIDTH = 260;
const HEADER_HEIGHT = 64;

const HeaderBar = styled('header', {
    height: HEADER_HEIGHT,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    borderBottom: '1px solid $gray4',
    position: 'fixed',
    background: 'white',//'$appHeaderSidebar',
    padding: '0 $md',
    zIndex: 200,
});
const HeaderLink = styled(Link, {
    display: 'inline-block',
    border: '1px solid $gray4',
    position: 'fixed',
    borderRadius: 4,
    padding: '$sm $md',
    fontWeight: '$bold',
    color: '$gray8',

    '&:hover': {
        color: '$foreground',
        background: '$gray3',
    },
});


const WATCH_THESE_EVENTS_TYPES = ['keydown', 'mouseover', 'beforeunload'];
const ERROR_PAGES = ['/logout'];

const TWENTY_FOUR_HOURS_SECS = 24 * 60 * 60;
const ONE_HOUR_SECS = 60 * 60;
const THIRTY_MINUTES_SECS = 30 * 60;
const FIVE_MINUTES_SECS = 5 * 60;

const SESSION_INACTIVITY_TIMEOUT_SEC = IS_LOCAL ? TWENTY_FOUR_HOURS_SECS : THIRTY_MINUTES_SECS;
const ISSUE_WARNING_OF_INACTIVITY_SEC = IS_LOCAL ? ONE_HOUR_SECS : FIVE_MINUTES_SECS;


export const AppHeader = ({user}) => {

    const [canSearchFfc, setCanSearchFfc] = useState(false);
    const [canSearchOther, setCanSearchOther] = useState(false);
    const [canViewFfc, setCanViewFfc] = useState(false);
    const [canCreateFfc, setCanCreateFfc] = useState(false);
    const [, getUsersRoles] = useHasRole({
        onCompleted: (response) => {
            // now let's unpack and see if user has what roles
            const roles = Object.freeze(response?.data?.hasRole);
            setCanSearchFfc(roles['search-ffc']);
            setCanSearchOther(roles['search-optumrx'] || roles['search-epim']);
            setCanViewFfc(roles['read-ffc']);
            setCanCreateFfc(roles['write-ffc']);
        },
        onError: (_err) => {
            setCanSearchFfc(false);
            setCanSearchOther(false);
            setCanViewFfc(false);
            setCanCreateFfc(false);
        },
    });
    const [_, _loadUserRoles] = useState(() => {
        // call the middleware to see if user has these roles
        return getUsersRoles( {
            variables: {
                roles: Object.freeze(['search-optumrx', 'search-epim', 'search-ffc', 'read-ffc', 'write-ffc'])
            }
        });
    });

    const [, registerLogout] = useLogout({
        onCompleted: (response) => {
            console.info(`Successfully sent log to NESS`);
        },
        onError: (err) => {
            console.log(`err => ${JSON.stringify(err)}`);
        },
    });

    const { navigate, getLocation  } = useRouter();

    hotkeys('f5,ctrl+r,command+r', function(event, handler){
        // Prevent the default refresh event
        event.preventDefault()
        console.log('Ignoring Refresh window!')
    });

    const [events, setEvents] = useState(WATCH_THESE_EVENTS_TYPES);

    const [, setIsCountdownComplete] = useState(false);

    const [count, { startCountdown, stopCountdown, resetCountdown }] = useCountdown({
        countStart: SESSION_INACTIVITY_TIMEOUT_SEC,
        intervalMs: 1_000,
    });

    const formattedTime = IS_LOCAL ? dayjs.duration(count * 1000).format('HH:mm:ss') : dayjs.duration(count * 1000).format('mm:ss');

    /** the details to display on toast */
    const toastDetails = {
        id: 'session',
        withCloseButton: true,
        title: 'Session Inactivity',
        message: `You will be logged out in ${formattedTime} min due to inactivity - please close this alert or hit any key to stay logged in.`,
        autoClose: ISSUE_WARNING_OF_INACTIVITY_SEC * 1_000,
        icon: <IconMaterial icon="warning_amber"/>,
        variant: 'warning',
    };

    const [isLoggedIn, setIsLoggedIn] = useState(!ERROR_PAGES.includes(getLocation().pathname));

    const userIsActive = (event) => {
        if(isLoggedIn && events.length) {
            resetCountdown();
            startCountdown();
        }
        return event;
    };

    const debounced = useDebounceCallback(userIsActive, 400);

    useEffect(() => {
        isLoggedIn && startCountdown();
    }, []);

    useEffect(() => {

        events.forEach(eventType => window.addEventListener(eventType, debounced, { passive: true }));
        // cleanup this component

        return () => {
            events.forEach(eventType => window.removeEventListener(eventType, debounced));
        };
    }, [events]);

    useEffect(() => {
        document.title = `dFEDS - Fraud Detection`; // TODO: We need to revisit what we will display on the tab window title
        if (isLoggedIn && count > ISSUE_WARNING_OF_INACTIVITY_SEC) toast.hide('session');
        if (isLoggedIn && count == ISSUE_WARNING_OF_INACTIVITY_SEC) toast.show(toastDetails);
        if (isLoggedIn && count < ISSUE_WARNING_OF_INACTIVITY_SEC) {
            toast.update(toastDetails);
            document.title = `Log off in ${formattedTime}`;
            // window.focus();
        }

        // console.log(`isLoggedIn = ${isLoggedIn}, formattedTime = ${JSON.stringify(formattedTime)}, events = ${JSON.stringify(events)}`);

        if (count === 0) {
            document.title = `Logged off!`;
            onTimeout();
        }

    }, [count ]);

    async function logout() {
        setIsCountdownComplete(true);
        stopCountdown();
        setIsLoggedIn(false);
        setEvents([]);

        if (!IS_LOCAL) {
            const logBackInUrl = config('LOG_BACK_IN_URL');
            console.log(`log back-in url: ${logBackInUrl}`);
            fetch(logBackInUrl, {
                credentials: "include",
                redirect: "follow",
                mode: "no-cors",
            })
            .then((response) => {
                console.log(`Logout Success @ ${Date.now()}:${response.statusText}`);
                return response;
            })
            .catch(reason => {
                console.log(`Logout error:${JSON.stringify(reason)}`);
            });
        }
    }
    async function logoutAndRedirect() {
        registerLogout({
            variables: {
                nessOptions: {
                    tags: ['UI', 'logout',],
                    additionalFields: {
                        'user-invoked': true,
                        'auto': false
                    },
                }
            }
        });

        await onLogOut();
    }

    async function onTimeout() {
        registerLogout({
            variables: {
                nessOptions: {
                    tags: ['UI', 'logout', 'session', 'timeout'],
                    additionalFields: {
                        'user-invoked': false,
                        'session': 'timeout',
                        'auto': true
                    },
                }
            }
        });

        await onLogOut();
    }

    async function onLogOut() {
        await logout();
        navigate('/logout');
        /**
         *  TODO: for now we'll turn this off as we need the EIS team to investigate this logout functionality further
         *  const url = 'https://maelstrom-cloud.optum.com/api/identity/logout';
         *  // @ts-ignore
         *  window.open(url);
         *
         */
    }

    // const DropdownMenuContent = () => {
    //     return (
    //         <NavMenuPrimitives.Columns ariaLabel="Sample Sub Menu">
    //             <NavMenuPrimitives.Column
    //                 title="FFC Providers"
    //                 ariaLabel="Sample Sub Menu Items"
    //             >
    //                 <NavMenuPrimitives.MenuItem
    //                     title="Search"
    //                     href="/provider/ffc/search"
    //                     icon='search'
    //                 >
    //                     <IconMaterial icon="search" size={16} />
    //                     Search existing providers.
    //                 </NavMenuPrimitives.MenuItem>
    //
    //                 <NavMenuPrimitives.MenuItem
    //                     title="Create"
    //                     href="/provider/ffc/create  "
    //                     // description="Description goes here"
    //                 >
    //                     <IconMaterial icon="create" size={16} />
    //                     Create a new provider
    //                 </NavMenuPrimitives.MenuItem>
    //             </NavMenuPrimitives.Column>
    //         </NavMenuPrimitives.Columns>
    //     );
    // };


    const DropdownMainMenuContentDisallowedEntities = () => {
        return (
            <NavMenuPrimitives.Columns>
                <NavMenuPrimitives.Column>
                    <PageHeaderPrimitives.DrawerLink
                        title="Providers"
                        path="/disallowed-entities/providers"
                        data-cy="menu-btn-providers"
                    />
                </NavMenuPrimitives.Column>
            </NavMenuPrimitives.Columns>
        );
    };

    const DropdownMenuContentProviderOptions = () => {
        return (
            <NavMenuPrimitives.Columns>
                <NavMenuPrimitives.Column>

                    {/* Is the user permitted to perform global provider search */}
                    {
                        (canSearchOther || canSearchFfc) &&
                        <NavMenuPrimitives.MenuItem
                            title="Global Provider Search"
                            href='/providers/global/search'
                            description="Global search of all providers"
                            data-cy="menu-btn-global-search-providers"
                        />

                    }

                    {/*<PageHeaderPrimitives.DrawerLink*/}
                    {/*  title="Managed FFC Cases"*/}
                    {/*  description="Description goes here"*/}
                    {/*  path="/disallowed-entities/providers/ffc"*/}
                    {/*/>*/}
                    {/*<PageHeaderPrimitives.DrawerLink*/}
                    {/*  title="EPIM"*/}
                    {/*  path="/disallowed-entities/providers/epim"*/}
                    {/*/>*/}
                    {/*<PageHeaderPrimitives.DrawerLink*/}
                    {/*  title="OptumRx"*/}
                    {/*  path="/disallowed-entities/providers/optumrx"*/}
                    {/*/>*/}

                    {/* Is the user permitted to view FFC providers */}
                    { (canViewFfc || canCreateFfc) &&
                        <NavMenuPrimitives.MenuItem
                            title="Manage FFC Cases"
                            href="/providers/manage"
                            description="Create or Edit FFC Cases"
                            data-cy="menu-btn-manage-ffc-cases"
                        />
                    }

                </NavMenuPrimitives.Column>

            </NavMenuPrimitives.Columns>
        );
    };

    // const DropdownMenuContentProviderFFCOptions = () => {
    //     return (
    //         <NavMenuPrimitives.Columns ariaLabel="Sample Sub Menu">
    //             <NavMenuPrimitives.Column
    //                 // title="FFC Provider Actions"
    //                 ariaLabel="Sample Sub Menu Items"
    //             >
    //                 <NavMenuPrimitives.MenuItem
    //                     title="Search"
    //                     href="/providers/search"
    //                     icon='search'
    //                 >
    //                     <IconMaterial icon="search" size={16} />
    //                     Search existing providers.
    //                 </NavMenuPrimitives.MenuItem>
    //
    //                 <NavMenuPrimitives.MenuItem
    //                     title="Create"
    //                     href="/providers/create  "
    //                     // description="Description goes here"
    //                 >
    //                     <IconMaterial icon="create" size={16} />
    //                     Create a new provider
    //                 </NavMenuPrimitives.MenuItem>
    //             </NavMenuPrimitives.Column>
    //         </NavMenuPrimitives.Columns>
    //     );
    // };


    return (
        <React.Fragment>
            <HeaderBar>
                {
                    isLoggedIn ?
                        <Flex alignItems="centre" direction="row" wrap="wrap">

                      <div style={{padding: '10px', margin: '-10px'}} data-cy="menu-account">
                          <AccountSection sidebarWidth={SIDEBAR_WIDTH} user={user}/>
                      </div>

                    <div style={{padding: '10px', margin: '10px'}}>
                <NavMenuPrimitives.Root zIndex={101} hasOverlay>
                    <NavMenuPrimitives.List>
                        {/* Disallowed Entities Menu */}
                        <NavMenuPrimitives.Item data-cy="menu-btn-disallowed-entities">
                            <NavMenuPrimitives.Trigger>Disallowed Entities</NavMenuPrimitives.Trigger>
                            <NavMenuPrimitives.Content>
                                <StateRouter.Provider initialPath="/disallowed-entities">
                                    <NavMenuPrimitives.RouterHeader/>
                                    <StateRouter.Routes>
                                        <StateRouter.Route path="/disallowed-entities" title="Disallowed Entities">
                                            {/* Shows the options - Providers, Email Address, Bank Account etc. */}
                                            <DropdownMainMenuContentDisallowedEntities/>
                                        </StateRouter.Route>

                                        {/* routes for when Providers is picked */}
                                        <StateRouter.Route path="/disallowed-entities/providers" title="Providers">
                                            {/* shows the provider options - ffc*/}
                                            <DropdownMenuContentProviderOptions/>

                                        </StateRouter.Route>
                                        {/* provider/ffc options - search, create etc. */}
                                        {/*<StateRouter.Route path="/disallowed-entities/providers/ffc" title="FFC" >*/}

                                        {/*  <DropdownMenuContentProviderFFCOptions />*/}

                                        {/*</StateRouter.Route>*/}


                                        {/*<StateRouter.Route path="/disallowed-entities/providers/epim" title="FFC" >*/}
                                        {/*  /!* provider/epim options - search, create etc. *!/*/}
                                        {/*  <DropdownMenuContentProviderFFCOptions />*/}

                                        {/*</StateRouter.Route>*/}

                                    </StateRouter.Routes>
                                </StateRouter.Provider>
                            </NavMenuPrimitives.Content>

                        </NavMenuPrimitives.Item>

                        {/* Scanner Reporting Menu */}
                        <NavMenuPrimitives.Item>
                            {/*<NavMenuPrimitives.Trigger>Scanner Reporting</NavMenuPrimitives.Trigger>*/}
                            <NavMenuPrimitives.Content>
                                {/*<StateRouter.Provider initialPath="/scanner-report">*/}
                                {/*  <NavMenuPrimitives.RouterHeader />*/}
                                {/*  <StateRouter.Routes>*/}
                                {/*    <StateRouter.Route path="/disallowed-entities" title="Disallowed Entities">*/}
                                {/*      /!* Shows the options - Providers, Email Address, Bank Account etc. *!/*/}
                                {/*      <DropdownMainMenuContentDisallowedEntities />*/}
                                {/*    </StateRouter.Route>*/}

                                {/*    /!* routes for when Providers is picked *!/*/}
                                {/*    <StateRouter.Route path="/disallowed-entities/providers" title="Providers" >*/}

                                {/*      <DropdownMenuContentProviderOptions />*/}

                                {/*    </StateRouter.Route>*/}

                                {/*    <StateRouter.Route path="/disallowed-entities/providers/ffc" title="FFC" >*/}

                                {/*      <DropdownMenuContentProviderFFCOptions />*/}

                                {/*    </StateRouter.Route>*/}

                                {/*  </StateRouter.Routes>*/}
                                {/*</StateRouter.Provider>*/}
                            </NavMenuPrimitives.Content>

                        </NavMenuPrimitives.Item>

                        {/* Graph-Visualisation Menu */}
                        <NavMenuPrimitives.Item>
                            {/*<NavMenuPrimitives.Trigger>Graph-Visualisation</NavMenuPrimitives.Trigger>*/}
                            <NavMenuPrimitives.Content>
                                {/*<StateRouter.Provider initialPath="/graph-vis">*/}
                                {/*  <NavMenuPrimitives.RouterHeader />*/}
                                {/*  <StateRouter.Routes>*/}
                                {/*    <StateRouter.Route path="/disallowed-entities" title="Disallowed Entities">*/}
                                {/*      /!* Shows the options - Providers, Email Address, Bank Account etc. *!/*/}
                                {/*      <DropdownMainMenuContentDisallowedEntities />*/}
                                {/*    </StateRouter.Route>*/}

                                {/*    /!* routes for when Providers is picked *!/*/}
                                {/*    <StateRouter.Route path="/disallowed-entities/providers" title="Providers" >*/}

                                {/*      <DropdownMenuContentProviderOptions />*/}

                                {/*    </StateRouter.Route>*/}

                                {/*    <StateRouter.Route path="/disallowed-entities/providers/ffc" title="FFC" >*/}

                                {/*      <DropdownMenuContentProviderFFCOptions />*/}

                                {/*    </StateRouter.Route>*/}

                                {/*  </StateRouter.Routes>*/}
                                {/*</StateRouter.Provider>*/}
                            </NavMenuPrimitives.Content>

                        </NavMenuPrimitives.Item>

                    </NavMenuPrimitives.List>

                    <NavMenuPrimitives.Viewport/>

                </NavMenuPrimitives.Root>
            </div>

                            <div style={{display: 'inline-block', marginTop: '30px'}}>
                <HeaderLink variant="custom" data-cy="logout"
                            onClick={logoutAndRedirect}>
                    <Layout.Group>
                        <IconMaterial icon="logout" color="$foreground"/>
                        <span>Logout</span>
                    </Layout.Group>
                </HeaderLink>
            </div>
                        </Flex>
                        : null
                }

                {
                    isLoggedIn ?
                      <Layout.Group>
                          <span>{formattedTime} mins</span>
                          <IconMaterial icon="logout" color="$foreground"/>
                          <span>until inactivity logout</span>
                      </Layout.Group>
                      : null
                }

            </HeaderBar>

        </React.Fragment>
    );

};
