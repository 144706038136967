import React, {useEffect, useState, useRef} from 'react';
import { Layout } from '@abyss/web/ui/Layout';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Link } from '@abyss/web/ui/Link';
import { DataTable } from '@abyss/web/ui/DataTable';
import { useDataTable } from '@abyss/web/hooks/useDataTable';
import { Button } from "@abyss/web/ui/Button";
import { useRouter } from '@abyss/web/hooks/useRouter';
import { Card } from "@src/abyss/web/ui/Card";
import { LoadingOverlay } from "@abyss/web/ui/LoadingOverlay";
import { IProviderHeading } from "@src/routes/provider/common/dataGridUtils";
import { Breadcrumbs } from "@src/abyss/web/ui/Breadcrumbs";
import { usePublish } from "@src/hooks/useNess";
import { useHasRole } from "@src/hooks/useHasRole";

// TODO; we need to create tests

export const ManageFFCCasesPage = ({providers, selectOptionsProps}) => {

	const [isLoading, setIsLoading] = useState(true);
	const [loadingMsg, ] = useState('Loading providers...');

	const myDiv = useRef();

	const [, nessPublisher] = usePublish({
		onCompleted: (response) => {
			console.info(`Successfully sent UPDATE event to NESS`);
		},
		onError: (err) => {
			console.log(`Failed to send UPDATE event to NESS with error => ${JSON.stringify(err)}`);
		},
	});

	const [canReadFfc, setCanReadFfc] = useState(false);
	const [canWriteFfc, setCanWriteFfc] = useState(false);
	const [iconName, setIconName] = useState('notes');
	const [, getUsersRoles] = useHasRole({
		onCompleted: (response) => {
			const roles = Object.freeze(response?.data?.hasRole);
			setCanReadFfc(roles['read-ffc']);
			setCanWriteFfc(roles['write-ffc']);
			setIconName(canWriteFfc ? 'edit_note': 'notes');
			if(!roles['read-ffc']) navigate('/');
		},
		onError: (_err) => {
			setCanReadFfc(false);
			setCanWriteFfc(false);
			navigate('/');
		},
	});
	const [, _loadUserRoles] = useState(() => {
		return getUsersRoles( {
			variables: {
				roles: Object.freeze(['read-ffc', 'write-ffc'])
			}
		});
	});

	/**
	 * Fire a 'Landed on Managed FFC Provider page' event to NESS logs
	 */
	const fireLandedOnManagedPageNessEvent = () => {
		/** publish an event to NESS */
		nessPublisher({
			variables: {
				event: {
					level: 'INFO',
					path: `providers/manage`,
					message: 'Manage FFC Provider page loaded successfully',
					tags: ['manage-ffc-provider-page-loaded'],
					query: 'N/A',
					op: 'GET',
					additionalFields: {
						'user-invoked' : true,
						isUIAction: true,
						event: 'MANAGE-FFC-PROVIDER-PAGE-LOADED',
					}
				},
			}
		});
	};

	const providerToColumn = (provider: IProviderHeading) => {
		return {
			id: `${provider.id}`,
			firstName: provider.firstName,
			lastName: provider.lastName,
			orgName: provider.orgName,
			providerTypeCode: selectOptionsProps?.providerTypes?.find((pair) => pair.value === provider.providerTypeCode)?.label,
			requestedDecisionCode: selectOptionsProps?.decisionCodes?.find((pair) => pair.value === provider.requestedDecisionCode)?.label,
			presentedDate: provider.presentedDate,
			tipSource: provider.tipSource,
			dollarBills: `$${provider.dollarBills}`,
			col11: true,
		};
	};

 	const loadData = () =>  {
		const data: [] = providers?.map((provider) => providerToColumn(provider)) || [];
		setIsLoading(false);
		return data;
	};

	const columns = React.useMemo(
		() => [
			{
				Header: 'Fraud Case Key',
				// isRowHeader: true,
				accessor: 'id',
				canToggleVisibilty	: false,
				canReorderColumn: false,
				isHiddenByDefault: false,
				disableSortBy: false,
				hiddenDefaultFilters: ['greater'],
				maxWidth: 150,
				customAPIFilters: [
					{
						value: 'includes',
						label: 'Includes',
					},
				],
				Cell: ({value}) => {
					const editUrl = `/providers/edit/${value}`;
					return (
						<Layout.Group>
							<IconMaterial icon={canWriteFfc ? 'edit_note':'notes'} size="$md"/>
							<Link href={editUrl} >
								{value}
							</Link>
						</Layout.Group>
					);
				},
			},
			{
				Header: 'First Name',
				accessor: 'firstName',
				canToggleVisibilty: false,
				isHiddenByDefault: false,
				disableSortBy: true,
				canReorderColumn: false,
				maxWidth: 150,
				hiddenDefaultFilters: ['greater'],
				customAPIFilters: [
					{
						value: 'includes',
						label: 'Includes',
					},
				],
			},
			{
				Header: 'Last Name',
				accessor: 'lastName',
				maxWidth: 150,
				canToggleVisibilty: false,
				canReorderColumn: false,
				isHiddenByDefault: false,
				label: 'Table Data 3',
				// Cell: ({value}) => {
				// 	return <Link href="/disallowed-entities/providers/managed/ffc/create">{value}</Link>;
				// },
			},
			{
				Header: 'Org Name',
				accessor: 'orgName',
				maxWidth: 250,
				canToggleVisibilty: false,
				isHiddenByDefault: false,
				disableSortBy: true,
				canReorderColumn: false,
			},
			{
				Header: 'Provider Type',
				accessor: 'providerTypeCode',
				canToggleVisibilty: true,
				isHiddenByDefault: false,
				disableSortBy: true,
				maxWidth: 255,
				canReorderColumn: true,
			},
			{
				Header: 'Requested Decision',
				accessor: 'requestedDecisionCode',
				canToggleVisibilty: true,
				isHiddenByDefault: false,
				disableSortBy: true,
				maxWidth: 150,
				canReorderColumn: true,
			},
			{
				Header: 'Presented Date',
				accessor: 'presentedDate',
				canToggleVisibilty: true,
				isHiddenByDefault: false,
				disableSortBy: true,
				maxWidth: 255,
				canReorderColumn: true,
			},
			{
				Header: 'Source of Tip',
				accessor: 'tipSource',
				canToggleVisibilty: true,
				isHiddenByDefault: false,
				disableSortBy: true,
				maxWidth: 255,
				canReorderColumn: true,
			},
			{
				Header: 'Billed Amount',
				accessor: 'dollarBills',
				canToggleVisibilty: true,
				isHiddenByDefault: false,
				disableSortBy: true,
				maxWidth: 150,
				canReorderColumn: true,
			},
		],
		[]
	);

	// TODO need a better way to get the results
	const data = React.useMemo(() => [...loadData()], [providers]);

	const dataTableProps = useDataTable({
		initialData: data,
		initialColumns: [],//columns,
		showSelection: false,
		showPagination: true,
		showGlobalFilter: true,
		showTableSettings: false,
		showFullscreenButton: false,
		highlightRowOnHover: true,
		showDownloadButton: true,
		showFilterDataset: true,
		showColumnVisibilityConfig: true,
		// additionalPaginationText: '(Can insert extra text here)',
		uniqueStorageId: 'Manage-FFC-Cases-data-table',
		onColumnVisibilityClose: (columns) =>
			console.log('columns', columns, dataTableProps.columnMgmt.allColumns),
		onColumnVisibilityOpen: (columns) =>
			console.log('columns', columns, dataTableProps.columnMgmt.allColumns),
	});

	const { navigate } = useRouter();
	const { getLocation } = useRouter();
	let location = getLocation();

	// useEffect(() => {
	//
	// 	if (!(canReadFfc || canWriteFfc)) {
	// 		setIsLoading(false);
	// 		navigate('/');
	// 	}
	//
	// }, [canReadFfc, canWriteFfc]);

	useEffect(() => {

		fireLandedOnManagedPageNessEvent();

		dataTableProps.setColumns([
			{
				Header: 'Fraud Case Key',
				// isRowHeader: true,
				accessor: 'id',
				canToggleVisibilty	: false,
				canReorderColumn: false,
				isHiddenByDefault: false,
				disableSortBy: false,
				hiddenDefaultFilters: ['greater'],
				maxWidth: 150,
				customAPIFilters: [
					{
						value: 'includes',
						label: 'Includes',
					},
				],
				Cell: ({value}) => {
					const editUrl = `/providers/edit/${value}`;
					return (
						<Layout.Group>
							<IconMaterial icon={canWriteFfc ? 'edit_note':'notes'} size="$md"/>
							<Link href={editUrl} >
								{value}
							</Link>
						</Layout.Group>
					);
				},
			},
			{
				Header: 'First Name',
				accessor: 'firstName',
				canToggleVisibilty: false,
				isHiddenByDefault: false,
				disableSortBy: true,
				canReorderColumn: false,
				maxWidth: 150,
				hiddenDefaultFilters: ['greater'],
				customAPIFilters: [
					{
						value: 'includes',
						label: 'Includes',
					},
				],
			},
			{
				Header: 'Last Name',
				accessor: 'lastName',
				maxWidth: 150,
				canToggleVisibilty: false,
				canReorderColumn: false,
				isHiddenByDefault: false,
				label: 'Table Data 3',
				// Cell: ({value}) => {
				// 	return <Link href="/disallowed-entities/providers/managed/ffc/create">{value}</Link>;
				// },
			},
			{
				Header: 'Org Name',
				accessor: 'orgName',
				maxWidth: 250,
				canToggleVisibilty: false,
				isHiddenByDefault: false,
				disableSortBy: true,
				canReorderColumn: false,
			},
			{
				Header: 'Provider Type',
				accessor: 'providerTypeCode',
				canToggleVisibilty: true,
				isHiddenByDefault: false,
				disableSortBy: true,
				maxWidth: 255,
				canReorderColumn: true,
			},
			{
				Header: 'Requested Decision',
				accessor: 'requestedDecisionCode',
				canToggleVisibilty: true,
				isHiddenByDefault: false,
				disableSortBy: true,
				maxWidth: 150,
				canReorderColumn: true,
			},
			{
				Header: 'Presented Date',
				accessor: 'presentedDate',
				canToggleVisibilty: true,
				isHiddenByDefault: false,
				disableSortBy: true,
				maxWidth: 255,
				canReorderColumn: true,
			},
			{
				Header: 'Source of Tip',
				accessor: 'tipSource',
				canToggleVisibilty: true,
				isHiddenByDefault: false,
				disableSortBy: true,
				maxWidth: 255,
				canReorderColumn: true,
			},
			{
				Header: 'Billed Amount',
				accessor: 'dollarBills',
				canToggleVisibilty: true,
				isHiddenByDefault: false,
				disableSortBy: true,
				maxWidth: 150,
				canReorderColumn: true,
			},
			], true);

		myDiv.current.focus();

	}, [location, providers, canReadFfc, canWriteFfc,]);


	return (
		<React.Fragment>

			<div tabIndex={-1} ref={myDiv} style={{ padding: '15px', margin: '10px', maxWidth: 'fit-content', marginLeft: 'auto', marginRight: 'auto' }}>
				{/* We look at how Volcan use this but this is only temp for now */}
				<Breadcrumbs
					// divider={'...'}
					isDarkMode
					leadingIcon={<IconMaterial icon="home" size="24px" />}
					items={[
						{ title: 'Home', href: '/' },
						{ title: 'Manage FFC Cases', href: '/providers/manage' },
					]}
				/>
			</div>

			<LoadingOverlay
				loadingTitle="Providers"
				loadingMessage={loadingMsg}
				ariaLoadingLabel="This is the ariaLoadingLabel prop."
				isLoading={isLoading}
			>
				<Card
					css={{
						'abyss-card-root': {
							height: 250,
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							backgroundColor: '$gray4',
						},
					}}
				>

				</Card>
			</LoadingOverlay>

			<div style={{padding: '5px', margin: '1px', width: '300px', display: `${canWriteFfc? 'inline-block':'none'}`}}>
				<Button
					variant="outline"
					before={<IconMaterial icon="create" color="$primary1"/> }
					onClick={() => {navigate('/providers/create');}}
					data-cy="mfc-btn-create"
				>Create New Case</Button>

			</div>

			<div>
				{/*{ ffcView && <DataTable tableState={dataTableProps}/> }*/}
				<DataTable tableState={dataTableProps}/>
			</div>

		</React.Fragment>
	);

};
