import React, { useEffect, useState } from 'react';
import { Button } from "@abyss/web/ui/Button";
import { IconMaterial } from "@abyss/web/ui/IconMaterial";
import { Card } from "@src/abyss/web/ui/Card";
import { LoadingOverlay } from "@abyss/web/ui/LoadingOverlay";
import { Tabs } from '@abyss/web/ui/Tabs';
import { Breadcrumbs } from "@src/abyss/web/ui/Breadcrumbs";
import { utils } from "@src/routes/provider/common/dataGridUtils";
import { Layout } from "@abyss/web/ui/Layout";
import { Label } from "@abyss/web/ui/Label";
import { Link } from "@abyss/web/ui/Link";
import { useDataTable } from "@abyss/web/hooks/useDataTable";
import { useOverlay } from "@abyss/web/hooks/useOverlay";
import { Drawer } from "@abyss/web/ui/Drawer";
import { DataTable } from "@abyss/web/ui/DataTable";
import { sourceUtils } from "@src/routes/provider/global/search/source/ResultsUtils";
import { useGetOptumRxGlobalSearch } from "@src/hooks/useOptumRxGlobalSearch";
import { useGetEpimSanctionsGlobalSearch } from "@src/hooks/useEpimSanctionsOrgGlobalSearch";
import { useGetProviderGlobalSearch } from "@src/hooks/useProviderGlobalSearch";
import { useHasRole } from "@src/hooks/useHasRole";
import { Alert } from "@abyss/web/ui/Alert";
import { isEmpty } from "lodash";
import { getReasonPhrase } from 'http-status-codes';

export const Results = ({ filterData, props }) => {

	const [states, _] = useState(props?.states);
	const [showErrorAlert, setShowErrorAlert] = useState(false);
	const [errorMessage, setErrorMessage] = useState(null);

	const drawer = useOverlay('cellOverflow-drawer');
	const { data: drawerData } = drawer.getState();
	const [drawerTitle, setDrawerTitle] = useState('');

	const [canSearchFfc, setCanSearchFfc] = useState(false);
	const [canSearchEpim, setCanSearchEpim] = useState(false);
	const [canSearchOptumRx, setCanSearchOptumRx] = useState(false);
	const [, getUsersRoles] = useHasRole({
		onCompleted: (response) => {
			// unpack and see if user has what roles
			const roles = Object.freeze(response?.data?.hasRole);
			setCanSearchFfc(roles['search-ffc']);
			setCanSearchOptumRx(roles['search-optumrx']);
			setCanSearchEpim(roles['search-epim']);

		},
		onError: (_err) => {
			// ok, something weird happened that we can't chat with middleware and get the roles
			setShowErrorAlert(true);
			setErrorMessage(_err.message || "Unexpected issue occurred");
			setCanSearchFfc(false);
			setCanSearchOptumRx(false);
			setCanSearchEpim(false);
			setIsFfcLoading(false);
			setIsOptumRxLoading(false);
		},
	});
	const [, _loadUserRoles] = useState(() => {
		// call the middleware to see if user has these roles
		return getUsersRoles( {
			variables: {
				roles: Object.freeze(['search-optumrx', 'search-epim', 'search-ffc'])
			}
		});
	});

	const searchCriteria = React.useMemo(() => filterData, [filterData]);

	const whenSource = (source) => filterData?.sources.includes(source);


	const [isFfcLoading, setIsFfcLoading] = useState(whenSource('ffc'));
	const [isOptumRxLoading, setIsOptumRxLoading] = useState(whenSource('optum-rx'));
	const [ffcLoadingMessage, ] = useState('Please wait while will load your FFC search results...');
	const [ffcLoadingTitle, ] = useState('Loading FFC search results');
	// for optum rx
	const [loadingMessage, ] = useState('Please wait while will load your OptumRx search results...');
	const [loadingTitle, ] = useState('Loading OptumRx search results');

	// FFC
	const [ffcTableData, setFfcTableData] = useState([]);
	const [ffcTabLabel, setFfcTabLabel] = useState('Loading results...');
	const [noFfcDataMsg, setNoFfcDataMsg] = useState('No Matching Records');

	const [, getFfcProviders] = useGetProviderGlobalSearch({
		onCompleted: async (response) => {
			try {
				const status = response?.data?.ffcGlobalSearch?.status;

				if (status === 200) {
					let providers = response?.data?.ffcGlobalSearch?.data || [];
					providers = providers?.map(p => ({
						...p,
						state: states.find(s => s.value === p.state)?.label,
					}));
					setFfcTableData(providers);
					setFfcTabLabel('FFC (' + providers.length + ')');
				} else {
					// got an error
					const error = response?.data?.ffcGlobalSearch;
					const reason = getReasonPhrase(error?.status);
					setFfcTabLabel('FFC (0)');
					setNoFfcDataMsg(`No Matching Records / ${reason} - ${error?.detail || 'Validation failed'}`);
				}

			} catch (e) {
				console.error('provider-global-search:Exception:%s', e);
				setFfcTabLabel('FFC (0)');
			} finally {
				setIsFfcLoading(false);
			}
		},
		onError: (err) => {
			const title = err?.type || "Unexpected error!";
			const message = err?.message || "An unexpected error occurred!";
			setFfcTabLabel('FFC (0)');
			console.error(`Error pulling back FFC providers : ${title}, ${message}`);
			setNoFfcDataMsg(`No Matching Records / ${title}, ${message}`);
			setIsFfcLoading(false);
		},
	});

	const ffcGridData = React.useMemo(() => [...ffcTableData], [ffcTableData]);

	const ffcColumns = React.useMemo(
		() => [
			{
				Header: 'Fraud Case ID',
				// isRowHeader: true,
				accessor: 'providerFraudCaseKey',
				canToggleVisibilty: false,
				canReorderColumn: false,
				isHiddenByDefault: false,
				disableSortBy: false,
				hiddenDefaultFilters: ['greater'],
				maxWidth: 150,
				Cell: ({value}) => {
					return (
						<Layout.Group>
							{/*<IconMaterial icon="chevron_left" size="$md"/>*/}
							<Label size="$md" >
								{value}
							</Label>
							{/*<IconMaterial icon="chevron_right" size="$md"/>*/}
						</Layout.Group>
					);
				},
			},
			{
				Header: 'Org Name',
				// isRowHeader: true,
				accessor: 'orgName',
				canToggleVisibilty: false,
				isHiddenByDefault: false,
				disableSortBy: false,
				canReorderColumn: false,
				maxWidth: 150,
				hiddenDefaultFilters: ['greater'],
			},
			{
				Header: 'First Name',
				// isRowHeader: true,
				accessor: 'firstName',
				canToggleVisibilty: false,
				isHiddenByDefault: false,
				disableSortBy: false,
				canReorderColumn: false,
				maxWidth: 150,
				hiddenDefaultFilters: ['greater'],
			},
			{
				Header: 'Last Name',
				accessor: 'lastName',
				maxWidth: 150,
				canToggleVisibilty: false,
				canReorderColumn: false,
				disableSortBy: false,
				isHiddenByDefault: false,
				label: 'Table Data 3',
			},
			{
				Header: 'ID Type',
				accessor: 'identifierTypeCode',
				maxWidth: 250,
				canToggleVisibilty: false,
				isHiddenByDefault: false,
				disableSortBy: false,
				canReorderColumn: false,
			},
			{
				Header: 'ID Value',
				accessor: 'identifierTypeValue',
				canToggleVisibilty: false,
				canReorderColumn: false,
				disableSortBy: false,
				isHiddenByDefault: false,
				maxWidth: 255,
			},
			{
				Header: 'Business Name',
				accessor: 'businessName',
				canToggleVisibilty: false,
				isHiddenByDefault: false,
				disableSortBy: false,
				canReorderColumn: false,
				maxWidth: 150,
			},
			{
				Header: 'Approver Decision',
				accessor: 'decisionFlagDescription',
				canToggleVisibilty: false,
				isHiddenByDefault: false,
				disableSortBy: false,
				canReorderColumn: false,
				maxWidth: 255,
			},
			{
				Header: 'Date',
				accessor: 'decisionFlagPlaceDate',
				canToggleVisibilty: false,
				isHiddenByDefault: false,
				disableSortBy: false,
				canReorderColumn: false,
				maxWidth: 255,
			},
			{
				Header: 'Reason',
				accessor: 'decisionReason',
				canToggleVisibilty: false,
				isHiddenByDefault: false,
				disableSortBy: false,
				canReorderColumn: false,
				maxWidth: 150,
			},
			{
				Header: 'Provider Type',
				accessor: 'providerTypeName',
				canToggleVisibilty: false,
				isHiddenByDefault: false,
				disableSortBy: false,
				canReorderColumn: false,
				maxWidth: 150,
			},
			{
				Header: 'Source Of Tip',
				accessor: 'sourceOfTip',
				canToggleVisibilty: true,
				isHiddenByDefault: true,
				disableSortBy: false,
				canReorderColumn: true,
				maxWidth: 150,
			},
			{
				Header: 'Requested Decision',
				accessor: 'requestedDecisionFlagDescription',
				canToggleVisibilty: true,
				isHiddenByDefault: true,
				disableSortBy: false,
				canReorderColumn: true,
				maxWidth: 150,
			},
			{
				Header: 'Presented Date',
				accessor: 'casePresentedDate',
				canToggleVisibilty: true,
				isHiddenByDefault: true,
				disableSortBy: false,
				canReorderColumn: true,
				maxWidth: 150,
			},
			{
				Header: 'Dollar Bill',
				accessor: 'billAmount',
				canToggleVisibilty: true,
				isHiddenByDefault: true,
				disableSortBy: false,
				canReorderColumn: true,
				maxWidth: 150,
			},
			{
				Header: 'Notes',
				accessor: 'notes',
				canToggleVisibilty: true,
				isHiddenByDefault: true,
				disableSortBy: false,
				canReorderColumn: true,
				maxWidth: 150,
			},
			{
				Header: 'Address 1',
				accessor: 'streetAddressLine1',
				canToggleVisibilty: true,
				isHiddenByDefault: true,
				disableSortBy: false,
				canReorderColumn: true,
				maxWidth: 150,
			},
			{
				Header: 'Address 2',
				accessor: 'streetAddressLine2',
				canToggleVisibilty: true,
				isHiddenByDefault: true,
				disableSortBy: false,
				canReorderColumn: true,
				maxWidth: 150,
			},
			{
				Header: 'State',
				accessor: 'state',
				canToggleVisibilty: true,
				isHiddenByDefault: true,
				disableSortBy: false,
				canReorderColumn: true,
				maxWidth: 150,
			},
			{
				Header: 'Postal Code',
				accessor: 'postalCode',
				canToggleVisibilty: true,
				isHiddenByDefault: true,
				disableSortBy: false,
				canReorderColumn: true,
				maxWidth: 150,
			},
			{
				Header: 'Removal Date',
				accessor: 'decisionFlagRemoveDate',
				canToggleVisibilty: true,
				isHiddenByDefault: true,
				disableSortBy: false,
				canReorderColumn: true,
				maxWidth: 150,
			},
			{
				Header: 'Removal Reason',
				accessor: 'decisionFlagRemoveReason',
				canToggleVisibilty: true,
				isHiddenByDefault: true,
				disableSortBy: false,
				canReorderColumn: true,
				maxWidth: 150,
			},
			{
				Header: 'Procedure Codes',
				accessor: 'procedureCodes',
				canToggleVisibilty: true,
				isHiddenByDefault: true,
				disableSortBy: false,
				canReorderColumn: true,
				maxWidth: 150,
			},
		],
		[ffcGridData]
	);

	const ffcDataTableProps = useDataTable({
		initialData: ffcGridData,
		initialColumns: ffcColumns,
		hideTitleHeader: true,
		showSelection: false,
		showPagination: true,
		showGlobalFilter: true,
		showTableSettings: false,
		showFullscreenButton: false,
		highlightRowOnHover: true,
		showDownloadButton: true,
		showFilterDataset: true,
		showColumnVisibilityConfig: true,
		noDataMessage: noFfcDataMsg,
		uniqueStorageId: 'ffc-data-table-usage',
	});


	// OPTUM-RX
	const [optumRxTabLabel, setOptumRxTabLabel] = useState('Loading results...');
	const [noOptumRxDataMsg, setNoOptumRxDataMsg] = useState('No Matching Records');
	const [optumRxTableData, setOptumRxTableData] = useState([]);

	const [, getOptumRxProviders] = useGetOptumRxGlobalSearch({
		onCompleted: async (response) => {
			try {
				const status = response?.data?.optumRxGlobalSearch?.status;

				if (status === 200) {
					let ps = response?.data?.optumRxGlobalSearch?.data || [];
					ps = ps?.map(p => ({
						...p,
						stateCode: states.find(s => s.value === p.stateCode)?.label,
					}));
					setOptumRxTableData(ps);
					setOptumRxTabLabel('OptumRx (' + ps.length + ')');
				} else {
					// got an error
					const error = response?.data?.optumRxGlobalSearch;
					const reason = getReasonPhrase(error?.status);
					setNoOptumRxDataMsg(`No Matching Records / ${reason} - ${error?.detail}`);
					setOptumRxTabLabel('OptumRx (0)');
				}

			} catch (e) {
				console.log('provider-global-search:Exception:%s', e);
				setOptumRxTabLabel('OptumRx (0)');
			} finally {
				setIsOptumRxLoading(false);
			}
		},
		onError: (err) => {
			setOptumRxTabLabel('OptumRx (0)');
			const title = err?.type || "Unexpected error!";
			const message = err?.message || "An unexpected error occurred!";
			// utils.popupMsg(title, message);
			// fire NESS log event here
			console.error(`Error pulling back FFC providers : ${title}, ${message}`);
			setNoOptumRxDataMsg(`No Matching Records / ${title}, ${message}`);
			setIsOptumRxLoading(false);
		},
	});

	const optumRxGridData = React.useMemo(() => [...optumRxTableData], [optumRxTableData]);

	const optumRxColumns = React.useMemo(
		() => [
			{
				Header: 'ID',
				// isRowHeader: true,
				accessor: 'id',
				canToggleVisibilty: false,
				canReorderColumn: false,
				isHiddenByDefault: false,
				disableSortBy: false,
				hiddenDefaultFilters: ['greater'],
				maxWidth: 150,
				Cell: ({value}) => {
					return (
						<Layout.Group>
							{/*<IconMaterial icon="flag" size="$md"/>*/}
							<Label size="$md">
								{value}
							</Label>
						</Layout.Group>
					);
				},
			},
			{
				Header: 'NCPDP',
				// isRowHeader: true,
				accessor: 'identifierTypeValue',
				canToggleVisibilty: false,
				isHiddenByDefault: false,
				disableSortBy: false,
				canReorderColumn: false,
				maxWidth: 150,
				hiddenDefaultFilters: ['greater'],
			},
			{
				Header: 'Name',
				// isRowHeader: true,
				accessor: 'pharmacyName',
				canToggleVisibilty: false,
				isHiddenByDefault: false,
				disableSortBy: false,
				canReorderColumn: false,
				maxWidth: 150,
				hiddenDefaultFilters: ['greater'],
			},
			{
				Header: 'City',
				accessor: 'city',
				maxWidth: 150,
				canToggleVisibilty: false,
				canReorderColumn: false,
				disableSortBy: false,
				isHiddenByDefault: false,
			},
			{
				Header: 'State',
				accessor: 'stateCode',
				maxWidth: 250,
				canToggleVisibilty: false,
				isHiddenByDefault: false,
				disableSortBy: false,
				canReorderColumn: false,
			},
			{
				Header: 'Action Taken',
				accessor: 'actionTaken',
				canToggleVisibilty: false,
				canReorderColumn: false,
				disableSortBy: false,
				isHiddenByDefault: false,
				maxWidth: 255,
			},
			{
				Header: 'Est. Termination Date',
				accessor: 'estimatedTerminationDate',
				canToggleVisibilty: false,
				isHiddenByDefault: false,
				disableSortBy: false,
				canReorderColumn: false,
				maxWidth: 200,
			},
			{
				Header: 'Dec. Reasons',
				accessor: 'decisionReasons',
				canToggleVisibilty: false,
				isHiddenByDefault: false,
				disableSortBy: false,
				canReorderColumn: false,
				maxWidth: 255,
				Cell: ({ value, row }) => {
					const isArray = Array.isArray(value);
					if (isArray && !value.length) return '';
					if (isArray && value.length == 1) return value;
					return (
						<Layout.Group css={{ justifyContent: 'space-between' }}>
							{isArray ? value[0] : value}
							{isArray && (
								<Link
									size="$sm"
									onClick={() => drawer.open({ value })}//utils.successPopupMsg('Global Search', `${JSON.stringify(value)}`)}
									aria-haspopup="dialog"
								>
									more...
								</Link>
							)}
						</Layout.Group>
					);
				},
			},
			{
				Header: 'Network Thru Date',
				accessor: 'networkThruDate',
				canToggleVisibilty: false,
				isHiddenByDefault: false,
				disableSortBy: false,
				canReorderColumn: false,
				maxWidth: 255,
			},
			{
				Header: 'Network Thru Date Reason',
				accessor: 'networkThruDateReason',
				canToggleVisibilty: false,
				isHiddenByDefault: false,
				disableSortBy: false,
				canReorderColumn: false,
				maxWidth: 150,
			},
			{
				Header: 'Participation',
				accessor: 'participation',
				canToggleVisibilty: true,
				isHiddenByDefault: true,
				disableSortBy: false,
				canReorderColumn: true,
				maxWidth: 150,
			},
			{
				Header: 'Address 1',
				accessor: 'streetAddressLine1',
				canToggleVisibilty: true,
				isHiddenByDefault: true,
				disableSortBy: false,
				canReorderColumn: true,
				maxWidth: 150,
			},
			{
				Header: 'Address 2',
				accessor: 'streetAddressLine2',
				canToggleVisibilty: true,
				isHiddenByDefault: true,
				disableSortBy: false,
				canReorderColumn: true,
				maxWidth: 150,
			},
			{
				Header: 'Postal Code',
				accessor: 'postalCode',
				canToggleVisibilty: true,
				isHiddenByDefault: true,
				disableSortBy: false,
				canReorderColumn: true,
				maxWidth: 150,
			},
		],
		[optumRxGridData]
	);
	const optumRxDataTableProps = useDataTable({
		initialData: optumRxGridData,
		initialColumns: optumRxColumns,
		showSelection: false,
		showPagination: true,
		showGlobalFilter: true,
		showTableSettings: false,
		showFullscreenButton: false,
		highlightRowOnHover: true,
		showDownloadButton: true,
		showFilterDataset: true,
		showColumnVisibilityConfig: true,
		noDataMessage: noOptumRxDataMsg,
		// additionalPaginationText: '(Can insert extra text here)',
		uniqueStorageId: 'optum-rx-data-table-usage',
	});

	// EPIM SANCTIONS
	const [epimSanctionsOrgTabLabel, setEpimSanctionsOrgTabLabel] = useState('Loading results...');
	const [epimSanctionsPractitionerTabLabel, setEpimSanctionsPractitionerTabLabel] = useState('Loading results...');

	const [noEpimSanctionsOrgDataMsg, setNoEpimSanctionsOrgDataMsg] = useState('No Matching Records');
	const [noEpimSanctionsPractitionerDataMsg,setNoEpimSanctionsPractitionerDataMsg] = useState('No Matching Records');

	const [, getEpimSanctionsOrgProviders] = useGetEpimSanctionsGlobalSearch( {
			onCompleted: async (response) => {
				// informLoading();
				try {
					const res = response?.data?.epimSanctionsGlobalSearch;
					if (res.status === 200) {
						setEpimSanctionsOrgTabLabel('EPIM Sanctions Org (' + res.totalElements + ')');
					} else {
						// got an error
						const error = (res?.data?.detail && res.data.detail.replace('Invalid EPIM request. ', '')) || getReasonPhrase(res.status) || 'Unexpected Error!';
						setNoEpimSanctionsOrgDataMsg(`No Matching Records / ${error}`);
						setEpimSanctionsOrgTabLabel('EPIM Sanctions Org (0)');
					}

				} catch (e) {
					setNoEpimSanctionsOrgDataMsg(`No Matching Records / ${e?.message}`);
					setEpimSanctionsOrgTabLabel('EPIM Sanctions Org (0)');
				} finally {

				}
			},
			onError: (err) => {
				const message = err?.message || "An unexpected error occurred!";
				setEpimSanctionsOrgTabLabel('EPIM Sanctions Org (0)');
				// fire NESS log event here
				setNoEpimSanctionsOrgDataMsg(`No Matching Records / ${message}`);
				// utils.popupMsg(title, message);

			},
		}
	);

	const [, getEpimSanctionsPractitionerProviders] = useGetEpimSanctionsGlobalSearch( {
			onCompleted: async (response) => {
				try {
					const res = response?.data?.epimSanctionsGlobalSearch;
					if (res.status === 200) {
						setEpimSanctionsPractitionerTabLabel('EPIM Sanctions Practitioner (' + res.totalElements + ')');
					} else {
						// got an error
						const error = (res?.data?.detail && res.data.detail.replace('Invalid EPIM request. ', '')) || getReasonPhrase(res.status) || 'Unexpected Error!';
						setNoEpimSanctionsPractitionerDataMsg(`No Matching Records / ${error}`);
						setEpimSanctionsPractitionerTabLabel('EPIM Sanctions Practitioner (0)');
					}

				} catch (e) {
					setNoEpimSanctionsPractitionerDataMsg(`No Matching Records / ${e?.message}`);
					setEpimSanctionsPractitionerTabLabel('EPIM Sanctions Practitioner (0)');

				} finally {

				}
			},
			onError: (err) => {
				const message = err?.message || "An unexpected error occurred!";
				setEpimSanctionsPractitionerTabLabel('EPIM Sanctions Practitioner (0)');
				// fire NESS log event here
				setNoEpimSanctionsPractitionerDataMsg(`No Matching Records / ${message}`);

			},
		}
	);

	const epimSanctionsOrgColumns = React.useMemo(
		() => [
			{
				Header: 'EPIM ID',
				// isRowHeader: true,
				accessor: 'epimId',
				canToggleVisibilty: false,
				canReorderColumn: false,
				isHiddenByDefault: false,
				disableSortBy: false,
				hiddenDefaultFilters: ['greater'],
				maxWidth: 150,
				Cell: ({value}) => {
					return (
						<Layout.Group>
							{/*<IconMaterial icon="numbers" size="$md"/>*/}
							<Link href={'https://data-steward-ui.epimoptum.com/goldenrecord/' + value} >
								{value}
							</Link>
						</Layout.Group>
					);
				},
			},
			{
				Header: 'Org Name',
				// isRowHeader: true,
				accessor: 'orgName',
				canToggleVisibilty: false,
				isHiddenByDefault: false,
				disableSortBy: false,
				canReorderColumn: false,
				maxWidth: 150,
				hiddenDefaultFilters: ['greater'],
			},
			{
				Header: 'NPI',
				accessor: 'npis',
				maxWidth: 250,
				canToggleVisibilty: false,
				isHiddenByDefault: false,
				disableSortBy: false,
				canReorderColumn: false,
				Cell: ({ value, row }) => {
					const isArray = Array.isArray(value);
					if (isArray && !value.length) return '';
					if (isArray && value.length == 1) return value;
					return (
						<Layout.Group css={{ justifyContent: 'space-between' }}>
							{isArray ? value[0] : value}
							{isArray && (
								<Link
									size="$sm"
									onClick={() => {
										setDrawerTitle("List of NPIs");
										drawer.open({ value })
									}}
									aria-haspopup="dialog"
								>
									more...
								</Link>
							)}
						</Layout.Group>
					);
				},
			},
			{
				Header: 'MPIN',
				accessor: 'mpins',
				canToggleVisibilty: false,
				canReorderColumn: false,
				disableSortBy: false,
				isHiddenByDefault: false,
				maxWidth: 255,
				Cell: ({ value, row }) => {
					const isArray = Array.isArray(value);
					if (isArray && !value.length) return '';
					if (isArray && value.length == 1) return value;
					return (
						<Layout.Group css={{ justifyContent: 'space-between' }}>
							{isArray ? value[0] : value}
							{isArray && (
								<Link
									size="$sm"
									onClick={() => {
										setDrawerTitle("List of MPINs");
										drawer.open({ value })
									}}
									aria-haspopup="dialog"
								>
									more...
								</Link>
							)}
						</Layout.Group>
					);
				},
			},
			{
				Header: 'TIN',
				accessor: 'tins',
				canToggleVisibilty: false,
				isHiddenByDefault: false,
				disableSortBy: false,
				canReorderColumn: false,
				maxWidth: 255,
				Cell: ({ value, row }) => {
					const isArray = Array.isArray(value);
					if (isArray && !value.length) return '';
					if (isArray && value.length == 1) return value;
					return (
						<Layout.Group css={{ justifyContent: 'space-between' }}>
							{isArray ? value[0] : value}
							{isArray && (
								<Link
									size="$sm"
									onClick={() => {
										setDrawerTitle("List of TINs");
										drawer.open({ value })
									}}
									aria-haspopup="dialog"
								>
									more...
								</Link>
							)}
						</Layout.Group>
					);
				},
			},
			{
				Header: 'Jurisdictions',
				accessor: 'states',
				canToggleVisibilty: false,
				isHiddenByDefault: false,
				disableSortBy: false,
				canReorderColumn: false,
				maxWidth: 255,
				Cell: ({value, row}) => {
					const isArray = Array.isArray(value);
					if (isArray && !value.length) return '';
					if (isArray && value.length == 1) return value;
					if (!value.includes('Federal')) return value.sort().join(" | ");
					return `Federal | ${value.filter(j => !j.match(/[fF]ederal/g)).sort().join(" | ")}`;
				}
			},
			{
				Header: 'Sanctions',
				accessor: 'sanction',
				canToggleVisibilty: false,
				isHiddenByDefault: false,
				disableSortBy: false,
				canReorderColumn: false,
				maxWidth: 150,
			},
		],
		[]
	);
	const epimSanctionsPractitionerColumns = React.useMemo(
		() => [
			{
				Header: 'EPIM ID',
				// isRowHeader: true,
				accessor: 'epimId',
				canToggleVisibilty: false,
				canReorderColumn: false,
				isHiddenByDefault: false,
				disableSortBy: false,
				hiddenDefaultFilters: ['greater'],
				maxWidth: 150,
				Cell: ({value}) => {
					return (
						<Layout.Group>
							<Link href={'https://data-steward-ui.epimoptum.com/goldenrecord/' + value} >
								{value}
							</Link>
						</Layout.Group>
					);
				},
			},
			{
				Header: 'First Name',
				// isRowHeader: true,
				accessor: 'firstName',
				canToggleVisibilty: false,
				isHiddenByDefault: false,
				disableSortBy: false,
				canReorderColumn: false,
				maxWidth: 150,
				hiddenDefaultFilters: ['greater'],
			},
			{
				Header: 'Last Name',
				accessor: 'lastName',
				maxWidth: 150,
				canToggleVisibilty: false,
				canReorderColumn: false,
				disableSortBy: false,
				isHiddenByDefault: false,
			},
			{
				Header: 'NPI',
				accessor: 'npis',
				maxWidth: 250,
				canToggleVisibilty: false,
				isHiddenByDefault: false,
				disableSortBy: false,
				canReorderColumn: false,
				Cell: ({ value, row }) => {
					const isArray = Array.isArray(value);
					if (isArray && !value.length) return '';
					if (isArray && value.length == 1) return value;
					return (
						<Layout.Group css={{ justifyContent: 'space-between' }}>
							{isArray ? value[0] : value}
							{isArray && (
								<Link
									size="$sm"
									onClick={() => {
										setDrawerTitle("List of NPIs");
										drawer.open({ value })
									}}
									aria-haspopup="dialog"
								>
									more...
								</Link>
							)}
						</Layout.Group>
					);
				},
			},
			{
				Header: 'MPIN',
				accessor: 'mpins',
				canToggleVisibilty: false,
				canReorderColumn: false,
				disableSortBy: false,
				isHiddenByDefault: false,
				maxWidth: 255,
				Cell: ({ value, row }) => {
					const isArray = Array.isArray(value);
					if (isArray && !value.length) return '';
					if (isArray && value.length == 1) return value;
					return (
						<Layout.Group css={{ justifyContent: 'space-between' }}>
							{isArray ? value[0] : value}
							{isArray && (
								<Link
									size="$sm"
									onClick={() => {
										setDrawerTitle("List of MPINs");
										drawer.open({ value })
									}}
									aria-haspopup="dialog"
								>
									more...
								</Link>
							)}
						</Layout.Group>
					);
				},
			},
			{
				Header: 'TIN',
				accessor: 'tins',
				canToggleVisibilty: false,
				isHiddenByDefault: false,
				disableSortBy: false,
				canReorderColumn: false,
				maxWidth: 255,
				Cell: ({ value, row }) => {
					const isArray = Array.isArray(value);
					if (isArray && !value.length) return '';
					if (isArray && value.length == 1) return value;
					return (
						<Layout.Group css={{ justifyContent: 'space-between' }}>
							{isArray ? value[0] : value}
							{isArray && (
								<Link
									size="$sm"
									onClick={() => {
										setDrawerTitle("List of TINs");
										drawer.open({ value })
									}}
									aria-haspopup="dialog"
								>
									more...
								</Link>
							)}
						</Layout.Group>
					);
				},
			},
			{
				Header: 'Jurisdictions',
				accessor: 'states',
				canToggleVisibilty: false,
				isHiddenByDefault: false,
				disableSortBy: false,
				canReorderColumn: false,
				maxWidth: 255,
				Cell: ({value, row}) => {
					const isArray = Array.isArray(value);
					if (isArray && !value.length) return '';
					if (isArray && value.length == 1) return value;
					if (!value.includes('Federal')) return value.sort().join(" | ");
					return `Federal | ${value.filter(jurisdiction => !jurisdiction.match(/[fF]ederal/g)).sort().join(" | ")}`;
				}
			},
			{
				Header: 'Sanctions',
				accessor: 'sanction',
				canToggleVisibilty: false,
				isHiddenByDefault: false,
				disableSortBy: false,
				canReorderColumn: false,
				maxWidth: 150,
			},
		],
		[]
	);

	const epimHook = {
		with: (source) => {
			return {
				call: (variables: any) => {
					switch (source) {
						case 'org':
							return getEpimSanctionsOrgProviders({ variables });
						default: // 'pract':
							return getEpimSanctionsPractitionerProviders({ variables });
					}
				}
			};
		}
	};

	const epimSanctions = (source) => {
		return async (page, pageSize, sortBy, globalFilter, columnFilters) => {
			const emptyResponse = {
				results: [],
				count: 0,
			};

			if (!searchCriteria?.sources.includes('epim-sanctions')) {
				return emptyResponse;
			}

			try {
				const filter = sourceUtils.epimFilterBuilder.with(searchCriteria).and(source).get();
				const variables = {
					filter: {
						...filter,
						source: source,
						pageNumber: page,
						pageSize: pageSize,
					}
				};

				const results = await epimHook.with(source).call(variables);

				const status = results?.data?.epimSanctionsGlobalSearch?.status;

				console.log(`EPIM search for ${source}, statue: ${status}, found ${results?.data?.epimSanctionsGlobalSearch?.totalElements || 0} providers`);

				// if (status !== 200) return emptyResponse;

				return {
					results: results?.data?.epimSanctionsGlobalSearch?.content || [],
					count: results?.data?.epimSanctionsGlobalSearch?.totalElements || 0,
				};

			} catch (e) {
				console.error(`Error retrieving ${source} providers`, e);
			}
			return emptyResponse;
		};
	};
	const epimSanctionsOrgDataTableProps = useDataTable({
		initialData: [],//epimSanctionsOrgGridData,
		initialColumns: epimSanctionsOrgColumns,
		usePagination: true,
		apiPaginationCall: epimSanctions('org'),//getEpimOrgData,
		paginationResultsTotalCount: 0,
		showSelection: false,
		showPagination: true,
		showGlobalFilter: false,
		showTableSettings: false,
		showFullscreenButton: false,
		highlightRowOnHover: true,
		showDownloadButton: false,
		showFilterDataset: false,
		showColumnVisibilityConfig: false,
		noDataMessage: noEpimSanctionsOrgDataMsg,
		// additionalPaginationText: '(Can insert extra text here)',
		uniqueStorageId: 'epim-sanctions-org-data-table-usage',
	});
	const epimSanctionsPractitionerDataTableProps = useDataTable({
		initialData: [],
		initialColumns: epimSanctionsPractitionerColumns,
		usePagination: true,
		apiPaginationCall: epimSanctions('pract'),
		showSelection: false,
		showPagination: true,
		showGlobalFilter: false,
		showTableSettings: false,
		showFullscreenButton: false,
		highlightRowOnHover: true,
		showDownloadButton: false,
		showFilterDataset: false,
		showColumnVisibilityConfig: false,
		noDataMessage: noEpimSanctionsPractitionerDataMsg,
		// additionalPaginationText: '(Can insert extra text here)',
		uniqueStorageId: 'optum-rx-data-table-usage',
	});

	useEffect(() => {
		const filter = sourceUtils.removeEmptyProps(searchCriteria);

		let variables = {
			filter: {
				...filter,
			}
		};

		if (canSearchFfc && whenSource('ffc')) {
			getFfcProviders({variables});
		}

		if (canSearchOptumRx && whenSource('optum-rx')) {
			getOptumRxProviders({ variables });
		}

		if (canSearchEpim && whenSource('epim-sanctions')) {
			epimSanctionsOrgDataTableProps.reloadTableData();
			epimSanctionsPractitionerDataTableProps.reloadTableData();
		}

	},[filterData, canSearchFfc, canSearchOptumRx, canSearchEpim]);

	useEffect(() => {

		if (canSearchFfc && whenSource('ffc')) {
			ffcDataTableProps.setData(ffcTableData, true);
		}
		if (canSearchOptumRx && whenSource('optum-rx')) {
			optumRxDataTableProps.setData(optumRxTableData, true);
		}

	},[ffcTableData, optumRxTableData, canSearchFfc, canSearchOptumRx	]);

	return (
		<React.Fragment>

			<div style={{ padding: '15px', margin: '10px', maxWidth: 'fit-content', marginLeft: 'auto', marginRight: 'auto' }}>
				{/* We look at how Volcan use this but this is only temp for now */}
				<Breadcrumbs
					// divider={'...'}
					isDarkMode
					leadingIcon={<IconMaterial icon="home" size="24px" />}
					items={[
						{ title: 'Home', href: '/' },
						// { title: 'Manage FFC Cases', href: '/providers/manage' },
						{ title: 'Global Provider Search Results', href: '/providers/global/search/results' },
					]}
				/>
			</div>

			<Drawer title={drawerTitle} model="cellOverflow-drawer">
				<Drawer.Section>
					{drawerData &&
						drawerData.value.map((item) => {
							return (
								<p key={item} style={{ marginBottom: 30 }}>
									<br/><IconMaterial icon="numbers" size="$md"/>	{item}
								</p>
							);
						})}
				</Drawer.Section>
			</Drawer>

			<Layout.Stack grow>
				<Alert
					title="Error"
					isVisible={showErrorAlert}
					onClose={() => {
						setShowErrorAlert(false);
						// if (buttonRef.current) {
						// 	buttonRef.current.focus();
						// }
					}}
				>
					{errorMessage}
				</Alert>
			</Layout.Stack>

			<Tabs grow={false} title="Tabs Sandbox" data-cy="gpsr-tab-results">

	{/* FFC Tab */}
				{
					(canSearchFfc && filterData?.sources.includes('ffc')) ?
						<Tabs.Tab label={ffcTabLabel} key='ffc-results' data-cy="gpsr-tab-results-ffc">
							<DataTable tableState={ffcDataTableProps} key='ffcDataTableProps' data-cy="gpsr-table-ffc" />
						</Tabs.Tab>
					: null
				}

	{/* Optum RX Tab */}
				{
					(canSearchOptumRx && filterData?.sources.includes('optum-rx')) ?
						<Tabs.Tab label={optumRxTabLabel} key='optum-rx-results' data-cy="gpsr-tab-results-optum-rx" >
							<DataTable tableState={optumRxDataTableProps} key='optumRxDataTableProps' data-cy="gpsr-table-optum-rx" />
						</Tabs.Tab>
					: null
				}

	{/* EPIM SANCTIONS Org Tab */}
				{
					(canSearchEpim && filterData?.sources.includes('epim-sanctions')) ?
						<Tabs.Tab label={epimSanctionsOrgTabLabel} key='epim-org-results' data-cy="gpsr-tab-results-epim-org" >
							<DataTable tableState={epimSanctionsOrgDataTableProps} key='epimSanctionsOrgDataTableProps' data-cy="gpsr-table-epim-org" />
						</Tabs.Tab>
					: null
				}
	{/* EPIM SANCTIONS Practitioner Tab */}
				{
					filterData?.sources.includes('epim-sanctions') ?

						<Tabs.Tab label={epimSanctionsPractitionerTabLabel} key='epim-practitioner-results' data-cy="gpsr-tab-results-epim-pract">
							<DataTable tableState={epimSanctionsPractitionerDataTableProps} key='epimSanctionsPractitionerDataTableProps' data-cy="gpsr-table-epim-pract" />
						</Tabs.Tab>
					: null
				}

			</Tabs>

			<LoadingOverlay
				loadingTitle={loadingTitle}
				loadingMessage={loadingMessage}
				ariaLoadingLabel="This is the ariaLoadingLabel prop."
				isLoading={isOptumRxLoading}
			>
				<Card
					css={{
						'abyss-card-root': {
							height: 150,
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							backgroundColor: '$gray4',
						},
					}}
				>

				</Card>
			</LoadingOverlay>
			<LoadingOverlay
				loadingTitle={ffcLoadingTitle}
				loadingMessage={ffcLoadingMessage}
				ariaLoadingLabel="This is the ariaLoadingLabel prop."
				isLoading={isFfcLoading}
			>
				<Card
					css={{
						'abyss-card-root': {
							height: 150,
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							backgroundColor: '$gray4',
						},
					}}
				>

				</Card>
			</LoadingOverlay>

			<Layout.Space />

			<Button variant="outline"
							before={<IconMaterial icon="arrow_back" color="$primary1" /> }
							href='/providers/global/search'
							data-cy="fpc-btn-cancel">Back to Search</Button>

		</React.Fragment>
	);
};
