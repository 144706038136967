import React, {useEffect} from 'react';
import { Heading } from '@abyss/web/ui/Heading';
import { PageBody } from '@abyss/web/ui/PageBody';
import { Link } from "@abyss/web/ui/Link";
import { useToast } from '@abyss/web/hooks/useToast';
import { IconMaterial } from "@abyss/web/ui/IconMaterial";
import { config } from "@abyss/api/tools/config";

export const LogoutPage = () => {
  const { toast } = useToast();
  const toastDetails = {
    id: 'logout',
    withCloseButton: true,
    title: 'Log Out',
    message: `You've been logged out - please click the link below to log back in.`,
    autoClose: 2 * 1_000,
    icon: <IconMaterial icon="info"/>,
    variant: 'info',
  };

  const ignoreBackButtonOnThisPage = () => {
    window.history.pushState(null, null, window.location.href);
    window.onpopstate = function () {
      window.history.go(1);
      toast.show(toastDetails);
    };
    toast.show(toastDetails);
  };


  useEffect(() => {
    ignoreBackButtonOnThisPage();
  }, []);

  return (
    <React.Fragment>

      <PageBody>
        <br/>
        <Heading offset={1} textAlign="center">dFEDS Logout</Heading>
        <br/>
        <br/>
        <Heading offset={2} textAlign="center">You've been logged out! <br/>
          <p>
            <br/>
            <Link href={`${config('LOG_BACK_IN_URL')}`} openNewWindow={true} data-cy="log-back-in-link">Please click here to sign back in. You can then close this tab.</Link>

          </p>
        </Heading>

        <br/><br/><br/>
        <Heading offset={6}
                 color="#ff0000"
                 textAlign="center">
          By logging into Optum Security Solutions, you are acknowledging that you are an authorized user; that
          unauthorized or improper use of Optum Security Solutions may result in disciplinary action as well as civil
          and criminal penalties; that Optum Security Solutions usage may be monitored, recorded, and subject to audit;
          that use of Optum Security Solutions indicated consent to monitoring and recording. The use of Optum Security
          Solutions by individual(s) or entity may allow access to information that is privileged, confidential and
          exempt from disclosure under applicable law, including the Health Insurance Portability and Accountability Act
          (PL 104-191).
        </Heading>
      </PageBody>


    </React.Fragment>
  );
};
