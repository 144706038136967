import React, { useMemo, useState } from 'react';
import { styled } from '@abyss/web/tools/styled';
import { useRouter } from "@abyss/web/hooks/useRouter";
import { Grid } from "@abyss/web/ui/Grid";
import { Heading } from "@abyss/web/ui/Heading";
import { Card } from "@abyss/web/ui/Card";
import { Link } from "@abyss/web/ui/Link";
import { useHasRole } from "@src/hooks/useHasRole";
import { IconMaterial } from "@abyss/web/ui/IconMaterial";

const NoPageContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  width: '100%',
});

export const HomePage = () => {
  const { getLocation } = useRouter();
  const location = getLocation();
  const { navigate } = useRouter();

  const [canSearchFfc, setCanSearchFfc] = useState(false);
  const [canSearchOthers, setCanSearchOthers] = useState(false);
  const [canReadFfc, setCanReadFfc] = useState(false);
  const [canWriteFfc, setCanWriteFfc] = useState(false);
  const [canAdmin, setCanAdmin] = useState(false);
  const [, getUsersRoles] = useHasRole({
    onCompleted: (response) => {
      const roles = Object.freeze(response?.data?.hasRole);
      setCanReadFfc(roles['read-ffc']);
      setCanWriteFfc(roles['write-ffc']);
      setCanSearchFfc(roles['search-ffc']);
      setCanSearchOthers(roles['search-epim'] || roles['search-optumrx']);
      setCanAdmin(roles['admin']);
    },
    onError: (_err) => {
      setCanReadFfc(false);
      setCanWriteFfc(false);
      setCanSearchFfc(false);
      setCanSearchOthers(false);
      setCanAdmin(false);
    },
  });
  const [, _loadUserRoles] = useState(() => {
    return getUsersRoles( {
      variables: {
        roles: Object.freeze(['read-ffc', 'write-ffc', 'admin', 'search-ffc', 'search-optumrx', 'search-epim'])
      }
    });
  });

  React.useEffect(() => {
    /**
     * we may have been routed here by Router cos user typed in some url that we do not know about.
     * So let's change the url path to '/' otherwise it could look weird, for example, if they
     * type '/this/does/not/exist' and they land on the home page but url does not say '/'
     */
    if (location?.pathname !== '/') {
      navigate('/');
    }

  }, [location]);

  /**
   * Below we've borrowed from the 'Volcan' codebase for the tile effect - big thanks to them.
   */
  const TrayTitle = styled('div', {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: '100%',
    textAlign: 'center',
  });
  const TrayLink = styled(Link, {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    border: '3px solid $gray4',
    borderRadius: 15,
    height: 140,
    padding: 10,
    transition: 'all 200ms ease',
    transitionPropery: 'background, borderColor',

    [TrayTitle]: {
      color: '$foreground',
    },

    svg: {
      fill: '$foreground',
    },

    '&:hover': {
      background: '$interactive3',
      borderColor: '$primary1',

      [TrayTitle]: {
        color: '$primary1',
      },

      svg: {
        fill: '$primary1',
      },
    },
  });

  const tiles = useMemo( () => [
    {
        id: 'admin',
        title: 'Administration',
        href: '/admin',
        icon: 'code',
        allowed: canAdmin,
      },
    {
        id: 'global-provider-search',
        title: 'Global Provider Search',
        href: '/providers/global/search',
        icon: 'search',
        allowed: (canSearchFfc || canSearchOthers),
      },
    {
        id: 'manage-ffc-cases',
        title: 'Manage FFC Cases',
        href: '/providers/manage',
        icon: 'manage_search',
        allowed: canReadFfc,
      },
    {
      id: 'create-provider',
      title: 'Create FFC Provider',
      href: '/providers/create',
      icon: 'create',
      allowed: canWriteFfc,
    },
    {
      id: 'help',
      title: 'Help',
      href: '/help',
      icon: 'help',
      allowed: true,
    },
    {
      id: 'release-notes',
      title: "What's new",
      href: '/whats-new',
      icon: 'new_releases',
      allowed: true,
    },
    ], [canSearchOthers, canSearchFfc, canAdmin, canReadFfc, canWriteFfc]);

  return (
    <NoPageContainer>
      <br/>
      <br/>
      <Heading>Digital Fraud Engineering Detection Services (dFEDS)</Heading>
      <br/>
      <br/><br/>

      <Card>
        <Card.Section>
          <Grid>
            {tiles?.map((item) => {
              const id = item.id || item.href;
              return (
                (item.allowed) &&
                <Grid.Col key={id} span={{ xs: '50%', lg: '33%' }}>
                  <TrayLink
                    href={item.href}
                    variant="custom"
                    data-testid={`tile-tray-link-${id}`}
                  >
                    <IconMaterial icon={item.icon || 'layers'} size={40} />
                    <TrayTitle>{item.title}</TrayTitle>
                  </TrayLink>
                </Grid.Col>
              );
            })}
          </Grid>
        </Card.Section>
      </Card>

      <br/>
    </NoPageContainer>
  );
};
