// import React, { useState, useEffect } from 'react';
import { styled } from '@abyss/web/tools/styled';
import { Button } from '@abyss/web/ui/Button';
import { Modal } from '@abyss/web/ui/Modal';
// import { Text } from '@abyss/web/ui/Text';
import { Layout } from '@abyss/web/ui/Layout';
import PropTypes from 'prop-types';

const StyledModal = styled(Modal, {
	'&.abyss-modal-content-container': {
		padding: '10px',
	},
});

// const StyledText = styled(Text, {
// 	'&.abyss-text-root': {
// 		fontSize: 20,
// 		fontWeight: 'bold',
// 	},
// });


export const ConfirmationModal = ({
																		title,
																		isOpen,
																		onClose,
																		onSubmit,
																		nextButton,
																		backButton,
																		message,
																		href,
																	}) => {
	return (
		<StyledModal title={title} isOpen={isOpen} onClose={onClose}>
			{message !== '' && (
				<Modal.Section align="center">{message}</Modal.Section>
			)}
			<Modal.Section  data-cy="modal-confirm">
				<Layout.Group alignLayout="center">
					{backButton !== '' && (
						<Button variant="outline" onClick={onClose}>
							{backButton}
						</Button>
					)}
					{href ? (
						<Button href={href}>{nextButton}</Button>
					) : (
						<Button onClick={onSubmit}>{nextButton}</Button>
					)}
				</Layout.Group>
			</Modal.Section>
		</StyledModal>
	);
};

ConfirmationModal.propTypes = {
	title: PropTypes.string.isRequired,
	isOpen: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	onSubmit: PropTypes.func,
	nextButton: PropTypes.string,
	backButton: PropTypes.string,
	message: PropTypes.string,
	href: PropTypes.string,
};

ConfirmationModal.defaultProps = {
	onSubmit: () => {},
	message: '',
	nextButton: 'I Understand',
	backButton: 'Cancel',
	href: null,
};
