import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { styled } from '@abyss/web/tools/styled';
import { Router } from '@abyss/web/ui/Router';
import { AppHeader } from '../AppHeader';
// import { AppFooter } from '../AppFooter';

import { ThemeProvider } from '@abyss/web/ui/ThemeProvider';
import { ToastProvider } from '@abyss/web/ui/Toast';
import {UnauthorizedErrorPage} from "src/common/Error";
import {useHasRole} from "@src/hooks/useHasRole";


const HEADER_HEIGHT = 24;
const FOOTER_HEIGHT = 24;

const FlexContainer = styled('div', {
  display: 'flex',
});

const FullWidth = styled('div', {
  width: '100%',
  overflowY: 'scroll',
  scrollSnapType: 'y mandatory',
  scrollSnapAlign: 'start',
  position: 'relative',
});

const MainContainer = styled('main', {
  marginTop: HEADER_HEIGHT,
  marginBottom: FOOTER_HEIGHT,
  position: 'relative',
  zIndex: 1,
  padding: '$xl',
  backgroundColor: '$appBackground',
  width: '100%',
  maxWidth: '100%',
});

const MainContainerWidth = styled('div', {
  maxWidth: '100%',
  margin: '0 auto',
});

export const AppBody = ({navItems, user}) => {

  const [, hasLoginRole] = useHasRole({
    onCompleted: (response) => {
      setLoginRole(response?.data.hasRole);
    },
    onError: (err) => {
      setLoginRole(false);
    },
  });
  const [loginRole, setLoginRole] = useState(async () => {
    return await hasLoginRole( {
      variables: {
        roles: ['login']
      }
    });
  });

  /**
   * what we expect to see rendered if the user has the login role
   */
  const renderWithAccess = () => {
    return (
    <React.Fragment>
      <ThemeProvider>
        <ToastProvider position="top-center"/>
        <FlexContainer>
          <AppHeader user={user}/>
          <FullWidth>
            <MainContainer>
              <MainContainerWidth>
                <Router.Outlet/>
              </MainContainerWidth>
            </MainContainer>
            {/* removing the footer for time-being <AppFooter /> */}
          </FullWidth>
        </FlexContainer>
      </ThemeProvider>
    </React.Fragment>
    );
  };

  /**
   * If the user does not have a login role then we'll show them this and request them to get secure groups
   */
  const renderNoAccess = () => {
    return (
      <React.Fragment>
        <ThemeProvider>
          <ToastProvider position="top-center"/>
          <FlexContainer>
            <FullWidth>
              <MainContainer>
                <MainContainerWidth>
                  <UnauthorizedErrorPage/>
                </MainContainerWidth>
              </MainContainer>
              {/* removing the footer for time-being <AppFooter /> */}
            </FullWidth>
          </FlexContainer>
        </ThemeProvider>
      </React.Fragment>
    );
  };

  return (

      loginRole ? (renderWithAccess()) : (renderNoAccess())

  );
};

// ToDO: Add more validation on props
AppBody.propTypes = {
  navItems: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      subItems: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          href: PropTypes.string,
        })
      ),
    })
  ).isRequired,
};
